.screen {
	min-height: 100vh;
}

.body {
	min-height: calc(100vh - 9rem - 2px) !important;
	padding: 2rem 0 !important;
}

.title {
	font-size: 1.3rem !important;
	font-weight: 700 !important;
	padding: 0 0 2rem 0 !important;
}

.subTitle {
	font-size: 1.2rem !important;
	font-weight: 700 !important;
	padding: 0 0 1rem 0 !important;
}

.whiteBox {
	padding: 1.5rem;
	background-color: var(--white);
	border-radius: 0.4rem;
	margin: 1rem 0 0 0;
	text-align: center;
}

.infoContainer {
	display: flex !important;
	flex-direction: row !important;
	justify-content: space-between !important;
	align-items: center !important;
}

.liensContainer {
	padding-bottom: 1rem !important;
}

.summaryContainer {
	display: flex !important;
	flex-direction: column !important;
	justify-content: space-evenly !important;
	align-items: center !important;
	background-color: var(--primary) !important;
	padding: 0.5rem 0 !important;
	height: 9rem !important;
}

.totalText {
	font-size: 1.2rem !important;
	font-weight: 700 !important;
	color: var(--white) !important;
}

.button {
	margin: 0 !important;
	padding: 0.75rem 1rem !important;
	background-color: var(--white) !important;
	color: var(--black) !important;
	justify-content: center !important;
	align-items: center !important;
}

.titleRow {
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	padding: 0 0 1rem 0 !important;
}

.row {
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	margin-bottom: 0.25rem !important;
}

.lienTitle {
	font-size: 1.2rem !important;
	font-weight: 700 !important;
	text-decoration: underline !important;
	margin-right: 0.5rem !important;
}

.lienText {
	font-size: 1rem !important;
	font-weight: 700 !important;
}

.lienSubText {
	font-size: 1rem !important;
}

.inputContainer {
	background-color: #e2e2e2;
	border: 1px solid var(--primary) !important;
	padding: 0.125rem 0.5rem !important;
	border-radius: 0.5rem !important;
}

.printButton {
	background-color: var(--primary) !important;
	color: var(--white) !important;
	justify-content: center !important;
	align-items: center !important;
}

@media (max-width: 550px) {
	.whiteBox {
		padding: 1.5rem !important;
	}
}

.headerContainer {
	display: flex !important;
	flex-direction: row !important;
	justify-content: space-between !important;
	align-items: center !important;
	margin-bottom: 1em !important;
}

.ctas {
	margin-left: auto;
	order: 2;
}

.sharePopup {
	padding: 1em !important;
	text-align: center !important;
}

.sharePopup h5 {
	margin: 1em 0 !important;
}

.buttonRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 1rem 0;
}

.row {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.titleRow {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.leftSide {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.rightSide {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
}

.titleContainer {
	flex: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.titleText {
	font-weight: 700 !important;
	text-transform: uppercase !important;
	text-align: center !important;
	font-size: 1.125rem !important;
}

.sectionContainer {
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 0.5rem 0;
	background-color: var(--green500);
}

.statusContainer {
	flex: 3;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 1rem;
}

.sectionText {
	font-weight: 700 !important;
	text-transform: uppercase !important;
}

.sectionRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 1.5rem 0 0 0;
}

.contentContainer {
	padding: 0.75rem;
	border: 1px solid var(--black);
}

.emptyText {
	font-weight: 700 !important;
	text-transform: capitalize !important;
	text-align: center !important;
}

.boldText {
	font-weight: 700 !important;
	text-transform: capitalize !important;
}

.text {
	padding-left: 0.25rem !important;
	text-transform: capitalize !important;
}

.addressText {
	padding-left: 0.25rem !important;
	text-transform: uppercase !important;
}

.subTitle {
	font-weight: 700 !important;
	text-transform: uppercase !important;
}

.textRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.25rem 0;
}

.addLienRows {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1rem 1rem 0 1rem;
}

.divider {
	font-weight: 700 !important;
	margin: 0 1.5rem !important;
}

.saveButtonsRow {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
	/* padding: 2rem 1rem 0; */
}

.logo {
	max-height: 6rem !important;
}

.disclaimerText {
	font-weight: 400 !important;
	font-size: 1em !important;
	color: #333 !important;
	width: 75%;
	text-align: center;
	line-height: 20px !important;
}

.image {
	margin-top: 2rem;
	height: 3rem;
	width: 18rem;
}

.test {
	text-align: "center" !important;
	padding-top: 1em !important;
}

.actions {
	display: flex !important;
	flex-direction: row !important;
	justify-content: space-between !important;
	align-items: center !important;
}

.totalLabel,
.totalPrice {
	font-weight: bold !important;
	font-size: 1.1em !important;
}
.totalPrice {
	color: var(--primary) !important;
}

.col {
	padding-top: 1.5px !important;
	padding-bottom: 1.5px !important;
	border-bottom: 2px solid #f5f5f5 !important;
}

.imageContainer {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	padding: 0.5rem;
}
