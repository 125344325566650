.screen {
  min-height: 100vh;
}

.body {
  min-height: calc(100vh - 12.5rem - 2px) !important;
  padding: 2rem 0 !important;
}

.title {
  font-size: 1.3rem !important;
  font-weight: 700 !important;
  padding: 0 0 2rem 0 !important;
}

.subTitle {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  padding: 0 0 1rem 0 !important;
}

.whiteBox {
  padding: 2rem !important;
  background-color: var(--white) !important;
  border-radius: 0.4rem !important;
}

.infoContainer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.liensContainer {
  padding-bottom: 1rem !important;
}

.summaryContainer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  background-color: var(--primary) !important;
  padding: 0.5rem 0 !important;
  height: 9rem !important;
}

.totalText {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  color: var(--white) !important;
}

.titleRow {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 0 0 1rem 0 !important;
}

.row {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  margin-bottom: 0.25rem !important;
}

.lienTitle {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  text-decoration: underline !important;
  margin-right: 0.5rem !important;
}

.lienText {
  font-size: 1rem !important;
  font-weight: 700 !important;
}

.lienSubText {
  font-size: 1rem !important;
}

.inputContainer {
  background-color: #e2e2e2;
  border: 1px solid var(--primary) !important;
  padding: 0.125rem 0.5rem !important;
  border-radius: 0.5rem !important;
}

@media (max-width: 550px) {
  .whiteBox {
    padding: 1.5rem !important;
  }
}

.headerContainer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start;
}

.ctas {
  margin-left: auto;
  order: 2;
}

.sharePopup {
  padding: 1em !important;
  text-align: center !important;
}

.sharePopup h5 {
  margin: 1em 0 !important;
}

.boldText {
  font-weight: bold !important;
}

.totalLabel {
  font-weight: bold !important;
  font-size: 1.25em !important;
}

.totalPrice {
  font-weight: bold !important;
  font-size: 1.25em !important;
}

.test {
  text-align: "center" !important;
  padding-top: 1em !important;
}

.actions {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.totalLabel,
.totalPrice {
  font-weight: bold !important;
  font-size: 1.1em !important;
}
.totalPrice {
  color: var(--primary) !important;
}

.col {
  padding-top: 1.5px !important;
  padding-bottom: 1.5px !important;
  border-bottom: 2px solid #f5f5f5 !important;
}

.notifBox {
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
}

.totalContainer {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  margin: 1em 0;
}

.totalText {
  font-size: 1.3rem !important;
  font-weight: 700 !important;
  color: var(--black) !important;
  margin: 0 0 0 0.5rem !important;
}

.titleText {
  font-size: 1.5rem !important;
  padding: 2rem 0 !important;
  font-weight: 700 !important;
  color: var(--primary) !important;
}

.totalTitleText {
  font-size: 1.5rem !important;
  padding: 0.5rem 0 !important;
  text-align: left;
  font-weight: 700 !important;
  color: var(--primary) !important;
}
.fees {
  color: var(--black) !important;
  font-size: 1.2rem !important;
  font-weight: bold !important;
}

.totalText {
  font-size: 1.3rem !important;
  font-weight: 700 !important;
  color: var(--black) !important;
  margin: 0 0 0 0.5rem !important;
}

.text {
  color: var(--black) !important;
  font-size: 1rem !important;
}

.paymentContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.payment {
  display: flex;
  flex: 1;
  padding: 1rem;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  padding: 0.75rem 1rem !important;
}
