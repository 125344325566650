.topContainer {
	top: 0;
	position: sticky;
	position: -webkit-sticky;
	background-color: var(--gray50);
	box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.3);
	z-index: 1;
}

.header {
	padding: 1rem 0 !important;
}

.searchBar {
	padding: 0.5rem 0 !important;
	background-color: var(--white) !important;
}

.row {
	display: flex !important;
	flex-direction: row !important;
	justify-content: space-between !important;
	align-items: center !important;
}

.logoContainer {
	flex: 1.5 !important;
}

.logo {
	width: 8rem !important;
	height: 2rem !important;
	opacity: 1 !important;
}

.hyperlinkRow {
	flex: 1.5 !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: space-between !important;
	align-items: center !important;
}

.burgerMenu {
	display: none !important;
	font-size: 1.25rem !important;
}

.hyperlink {
	background: none !important;
	padding: 0 !important;
	margin: 0 !important;
	font-weight: 700 !important;
	line-height: 1 !important;
	color: var(--black) !important;
	opacity: 1 !important;
}

.hyperlinkActive {
	background: none !important;
	padding: 0 !important;
	margin: 0 !important;
	font-weight: 700 !important;
	line-height: 1 !important;
	color: var(--primary) !important;
	opacity: 1 !important;
}

.hyperlink:hover {
	color: var(--primary) !important;
}

.blackBar {
	background-color: var(--black) !important;
	padding: 0.5rem 0 !important;
}

.blackBarhyperLink {
	background: none !important;
	padding: 0 0 0 0.5rem !important;
	margin: 0 !important;
	font-weight: 400 !important;
	font-size: 1rem !important;
	line-height: 1 !important;
	color: var(--white) !important;
	opacity: 1 !important;
}

.icon {
	margin: 0 !important;
	color: var(--white) !important;
	opacity: 1 !important;
}

.socialIcon {
	margin: 0 0 0 1rem !important;
	color: var(--white) !important;
	opacity: 1 !important;
	font-size: 1rem !important;
}

.socialIcon:hover {
	cursor: pointer !important;
}

.button {
	background: none !important;
	margin: 0 !important;
	padding: 0 !important;
}

.contactSide {
	flex: 3 !important;
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
}

.socialMediaSide {
	flex: 1 !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: flex-end !important;
	align-items: center !important;
}

.blackBarContent {
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	margin-right: 1.5rem !important;
}

.blackBarContent:hover {
	cursor: pointer !important;
}

.blackBarContent:hover .icon {
	cursor: pointer;
	color: var(--primary) !important;
}

.blackBarContent:hover .blackBarhyperLink {
	color: var(--primary) !important;
}

.profileHyperlink {
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
}

.profileHyperlink:hover .hyperlink {
	color: var(--primary) !important;
}

.avatar {
	margin-right: 0.5rem !important;
}

.menuItem {
	display: flex !important;
	flex-direction: row !important;
	justify-content: space-between !important;
	align-items: center !important;
	padding: 1rem !important;
}

.mobileMenuItem {
	display: flex !important;
	flex-direction: row !important;
	justify-content: space-between !important;
	align-items: center !important;
	padding: 1rem !important;
}

.mobileMenuItem:hover .inactiveIcon {
	color: var(--primary) !important;
}

.mobileMenuItem:hover .hyperlink {
	color: var(--primary) !important;
}

.menuItem:hover .inactiveIcon {
	color: var(--primary) !important;
}

.menuItem:hover .hyperlink {
	color: var(--primary) !important;
}

.item {
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
}

.activeIcon {
	color: var(--primary) !important;
	margin-right: 0.5rem !important;
}

.inactiveIcon {
	color: var(--black) !important;
	margin-right: 0.5rem !important;
}

.badge {
	padding: 0 !important;
	font-size: 0.75rem !important;
	padding: 0.125rem 0.5rem !important;
	margin: 0 !important;
}

.mobileMenu {
	width: calc(100vw - 0.75rem) !important;
}

/* Media Queries */
@media (max-width: 1199.98px) {
	.logoContainer {
		flex: 1 !important;
	}
}

@media (max-width: 991px) {
	.logoContainer {
		flex: 0.5 !important;
	}
}

@media (max-width: 768px) {
	.burgerMenu {
		display: flex !important;
	}

	.hyperlinkRow {
		display: none !important;
	}
}

@media (max-width: 550px) {
	.blackBarhyperLink {
		display: none !important;
	}
}
