.container {
  position: relative;
  top: 0;
  padding: 0.5rem 0;
  background-color: var(--gray50);
}

.row {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.toggleContainer {
  width: 15rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
}

.toggle {
  color: var(--primary) !important;
}

.input {
  margin: 0 1rem 0 0 !important;
  width: 100% !important;
}

/* .input2 {
  margin: 0 0.5rem !important;
}

.input3 {
  margin: 0 0.5rem !important;
  width: 5rem !important;
} */

.button {
  margin: 0 !important;
  padding: 0.675rem 1rem !important;
  background-color: var(--primary) !important;
  color: var(--white) !important;
}

.button:hover {
  background-color: var(--green300) !important;
}

/* @media (max-width: 768px) {
  .input {
    flex: 19 !important;
  }

  .button {
    flex: 6 !important;
  }
}

@media (max-width: 550px) {
  .input {
    flex: 18 !important;
  }

  .button {
    flex: 7 !important;
  }
} */

.clearIconLast {
  border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-right: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-left: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  background-color: white !important;
  margin: 0 !important;
  padding: 0 0.5rem !important;
}

.underlyingInput {
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

