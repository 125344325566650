.container {
  width: 100% !important;
  height: 12rem !important;
  border: 1px solid !important;
  border-radius: 0.5rem !important;
  background-image: url("../../assets/login-background.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.black:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.black {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-end !important;
  align-items: center !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  padding: 0.5rem !important;
}

.black2:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.black2 {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  padding: 0.5rem !important;
}

.text {
  color: var(--white) !important;
  text-transform: uppercase !important;
}
