.infoBit {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem 0;
}

.upper {
  text-transform: uppercase !important;
}

.capital {
  text-transform: capitalize !important;
}

.iconContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.infoIcon {
  font-size: 1.5rem !important;
  color: var(--primary) !important;
}

.infoText {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  margin-left: 1rem;
}

.title {
  font-weight: 700 !important;
  font-size: 1.125rem !important;
}

.text {
  font-weight: 500 !important;
  font-size: 1rem !important;
}

.hyperlink {
  text-decoration: underline !important;
}

.hyperlink:hover {
  cursor: pointer;
}
