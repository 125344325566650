.screen {
  min-height: 100vh !important;
}

.body {
  display: flex !important;
  height: calc(100vh - 12.5rem - 2px) !important;
  align-items: center !important;
  justify-content: center !important;
}

.container {
  display: flex !important;
  height: 100% !important;
  padding: 0.5rem 0 !important;
}

.navBar {
  display: flex !important;
  flex: 1 !important;
  background-color: var(--primary) !important;
}

.content {
  display: flex !important;
  flex-direction: column !important;
  flex: 4 !important;
  overflow-y: auto !important;
  padding: 1em !important;
}

.boldedText {
  font-weight: bold !important;
}

@media screen and (max-width: 1200px) {
  .navBar {
    flex: 1.25 !important;
  }
}

@media screen and (max-width: 992px) {
  .navBar {
    flex: 1.5 !important;
  }
}

@media screen and (max-width: 768px) {
  .body {
    height: calc(100vh - 15.5rem - 1px) !important;
  }

  .navBar {
    flex: 1.5 !important;
  }
}


.cartHeader {
  font-weight: bold !important;
  margin-bottom: 0.6em !important;

}

.container1 {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  border-radius: 0.25rem !important;
  padding: 0.5rem !important;
  background-color: white !important;
}

.container2 {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  border-radius: 0.25rem !important;
  padding: 0.5rem !important;
  background-color: none !important;
}




.infoContainer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.buttonsContainer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.button {
  margin: 0 0 0 0.5rem !important;
  padding: 0.5rem 1rem !important;
  color: var(--white) !important;
  justify-content: center !important;
  align-items: center !important;
}

.icon {
  margin: 0 !important;
}

.textContainer {
  margin: 0.25rem 0;
}

.address {
  text-transform: uppercase !important;
}

.row {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.timeText {
  font-size: 0.875rem !important;
  font-style: italic !important;
  opacity: 0.95 !important;
}

.noOrders {
  font-size: 1m !important;
  font-weight: bold !important;
}

.cartRowItem {
  border-bottom:1px solid #efefef !important
}

.balance {
 color: var(--primary) !important;
}