.screen {
  min-height: 100vh;
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bodyWithBG {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.black {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.container {
  height: 100% !important;
  padding: 1.5rem 0 !important;
}

.noContainer {
  width: 100% !important;
  height: 100% !important;
}

.title {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  padding-bottom: 2rem !important;
}

.rowContent {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  overflow-y: auto;
}

.columnContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
}

