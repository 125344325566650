.header {
  z-index: 2;
}

.mainSection {
  z-index: 1;
}

.webBody {
  z-index: 2;
}
