.titleRow {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.leftSide {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.rightSide {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
}

.titleContainer {
	flex: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.titleText {
	font-weight: 700 !important;
	text-transform: uppercase !important;
	text-align: center !important;
	font-size: 1.125rem !important;
}

.logo {
	max-height: 6rem !important;
	min-height: 4rem !important;
}

.logo2 {
	max-height: 5rem !important;
}
