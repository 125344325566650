.sidebar {
  display: flex !important;
  flex-direction: row !important;
  background-color: var(--gray50) !important;
  padding: 0 !important;
  overflow-y: hidden !important;
}

.double {
  width: 600px !important;
}

.single {
  width: 300px !important;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  margin: 0 !important;
  padding: 0.5rem !important;
  width: auto;
}

.icon:hover {
  cursor: pointer !important;
}

.heartIcon {
  color: var(--red500) !important;
}

.text {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
}

.amountText {
  font-size: 2rem !important;
  font-weight: bold !important;
  padding: 0.5rem !important;
}

.symbol {
  color: var(--primary) !important;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
}

.titleText {
  font-weight: bold !important;
  text-transform: capitalize !important;
  margin: 0 0 1rem 0 !important;
}

.button {
  background-color: var(--primary) !important;
  padding: 0.75rem 1rem !important;
}

.buttonText {
  color: var(--white) !important;
}

.rightSection {
  width: 300px;
  padding: 1rem;
  height: 100%;
  border-left: 1px solid black;
  overflow-y: auto;
}

.leftSection {
  width: 300px;
  height: 100%;
  border-left: 1px solid black;
  overflow-y: auto;
}

.sectionTitle {
  padding: 1rem;
  border-bottom: 1px solid black;
}

.liensContainer {
  padding: 1rem;
}

.title {
  font-weight: bold !important;
  text-align: center !important;
  font-size: 1.25rem !important;
}

.preview {
  border: 1px solid !important;
  border-radius: 0.5rem !important;
  height: 8rem !important;
  width: 100% !important;
  object-fit: cover !important;
}
