.screen {
  min-height: 100vh !important;
}

.body {
  display: flex !important;
  height: calc(100vh - 9rem - 2px) !important;
  align-items: center !important;
  justify-content: center !important;
  background-image: url("../../assets/login-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.gridContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.black {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-evenly !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
}

@media (max-width: 768px) {
  .body {
    height: calc(100vh - 12rem - 1px) !important;
  }
}
