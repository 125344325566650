.screen {
  min-height: 100vh !important;
}

.body {
  min-height: calc(100vh - 12.5rem - 2px) !important;
  padding: 2rem 0 !important;
}

.titleText {
  font-size: 1.5rem !important;
  padding: 2rem 0 !important;
}

@media (max-width: 768px) {
  .body {
    height: calc(100vh - 15.5rem - 2px) !important;
  }
}
