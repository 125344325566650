.canvas {
  position: absolute;
  top: 105em;
  background-color: white;
  width: 100vw;
  height: 1800px;
  /* outline: 2.5px dashed red; */
}

.cover {
  z-index: 11;
  position: absolute;
  top: 290em;
  background-color: white;
  width: 100vw;
  height: 20px;
}

.grid {
  position: absolute !important;
  top: -120em;
  left: 50vw;
}

.small_blob {
  position: absolute;
  width: 12.88em;
  height: 12.32em;
  /* outline: 2.5px dashed red; */
}

.left_small_blob {
  left: -3em;
  top: -3em;
}

.right_small_blob {
  right: -3em;
  top: -3em;
}

.panelimg {
  /* outline: 2.5px dashed red; */
  position: absolute;
}

.img1 {
  /* panel 1 img and blob */
  right: 0em;
  top: -4em;
  width: 591.952px;
  height: 560.96px;
}

.img2 {
  /* panel 2 img and blob */
  left: 0em;
  top: -9em;
  width: 591.104px;
  height: 587.24px;
}

.img3 {
  /* panel 3 img and blob */
  right: 0em;
  top: 0em;
  width: 553.872px;
  height: 449.752px;
}

.divider {
  position: absolute;
  width: 95em;
  height: 0.2em;
  background: #1d85cc;
  filter: blur(0.6px);
}

.divider1 {
  /* divider between panel 1 and 2 */
  top: 177.5em;
}

.divider2 {
  /* divider between panel 2 and 3 */
  top: 233em;
}

.rectangle {
  /* panel styling */
  position: absolute;
  width: 123.2em;
  height: 44.56em;
  background: #ffffff;
  box-shadow: 0em 0.4em 0.6em 0.6em rgba(0, 0, 0, 0.15);
  border-radius: 4.5em;
}

.rectangle1 {
  /* panel 1 position*/
  top: 127em;
}

.rectangle2 {
  /* panel 2 position */
  top: 182.5em;
}

.rectangle3 {
  /* panel 3 position */
  top: 238em;
}

.subheader {
  /* subheading style */
  font-family: "Rambla";
  font-style: normal;
  font-weight: 700;
  font-size: 38.4px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #104aab;

  /* outline: 2.5px dashed red; */
}

.subheader1 {
  /* panel 1 title position */
  position: absolute;
  left: 8em;
  top: 4em;
}

.subheader2 {
  /* panel 2 title position */
  position: absolute;
  right: 6em;
  top: 4em;
}

.subheader3 {
  /* panel 3 title position */
  position: absolute;
  left: 9.25em;
  top: 4em;
}

.paragraph {
  /* panel text style*/
  width: 22em;
  font-family: "Rambla";
  font-style: normal;
  font-weight: 400;
  font-size: 22.4px;
  line-height: 1.3em;
  display: flex;
  align-items: center;
  text-align: right;
  color: #104aab;

  /* outline: 2.5px dashed green; */
}

.paragraph1 {
  /* panel 1 text position */
  position: absolute;
  top: 9em;
  left: 7.38em;
}

.paragraph2 {
  /* panel 2 text position*/
  position: absolute;
  right: 6.05em;
  top: 9em;
  text-align: left;
}

.paragraph3 {
  /* panel 3 text position*/
  position: absolute;
  top: 9em;
  left: 7.38em;
}
.paragraph4 {
  /* panel 3 text position*/
  position: absolute;
  top: 75em;
  left: 35em;
}

.button {
  /* panel 3 "Try Limas Now!" */
  position: absolute;
  width: 8em;
  left: 25.05em;
  top: 15em;
  padding: 25px 0;

  background: #479bff;
  box-shadow: 0em 0.4em 0.4em rgba(0, 0, 0, 0.25);
  border-radius: 0.5em;
  border: none;

  color: #ffffff;
  font-size: 2em;
  font-weight: normal;
  font-family: "Rambla";

  cursor: pointer;
}

.requestdemo {
  /*background for request demo section*/
  position: absolute;
  width: 1536px;
  /* height: 729.6px; */
  top: 290em;
  background-image: url(../assets/Background.png);
}

.form {
  position: absolute;
  width: 719px;
  height: 541px;
  left: 40em;
  top: 10em;
  /* outline: 2.5px dashed green; */
}

.formHeader {
  position: absolute;
  left: 2.5em;
  top: 0.5em;
  width: 14em;

  font-family: "Rambla";
  font-style: normal;
  font-weight: 700;
  font-size: 38.4px;
  line-height: 1.5em;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  /* outline: 2.5px dashed red; */

  text-shadow: 0px 0.4em 0.4em rgba(0, 0, 0, 0.25);
}

.formtext {
  font-family: "Rambla";
  font-style: normal;
  font-size: 16px;
  background-color: #ffffff;
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.formName {
  position: absolute;
  top: 13em;
  left: 0em;
  width: 306px;
  height: 39px;

  border-radius: 17.5px;
  border: none;
}

.formEmail {
  position: absolute;
  top: 13em;
  right: 0em;
  width: 306px;
  height: 39px;

  border-radius: 17.5px;
  border: none;
}

.formNumber {
  position: absolute;
  top: 17em;
  left: 0em;
  width: 306px;
  height: 39px;

  border-radius: 17.5px;
  border: none;
}

.formUserText {
  position: absolute;
  top: 21em;
  left: 0em;
  width: 719px;
  height: 143px;
  padding: 5px;
  border-radius: 15px;
}

.formButton {
  /*Submit*/
  position: absolute;
  bottom: 0em;
  left: 10em;
  height: 2em;
  width: 20em;
  background: #479bff;
  border: none;
  border-radius: 100px;

  font-family: "Rambla";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8em;
  display: block;
  align-items: center;
  text-align: center;

  color: #ffffff;

  cursor: pointer;
}
