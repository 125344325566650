.textRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.5rem 0;
}

.boldText {
	font-size: 1.125rem !important;
	font-weight: 700 !important;
}

.text {
	font-size: 1.125rem !important;
	padding-left: 0.5rem !important;
	text-transform: capitalize !important;
}

.text {
	display: flex !important;
	flex-direction: row !important;
	font-size: 1.125rem !important;
	padding-left: 0.5rem !important;
	text-transform: capitalize !important;
}

.dashedText {
	font-size: 1.125rem !important;
	padding-right: 0.5rem !important;
	text-transform: capitalize !important;
	text-decoration: line-through !important;
	text-decoration-color: red !important;
	text-decoration-thickness: 0.125rem !important;
}
