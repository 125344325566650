@font-face {
    font-family: 'RamblaBold';
    src: url(../../assets/Rambla-Bold.ttf);
}

.canvas {
    position: absolute;
    width: 100%;
    height: 53em;
    top: 52.24em;

    background: #FFFFFF;
    z-index: 10;
}

.manageLiens {
    opacity: 0;
    z-index: 11;
    position: relative;
    width: 7em;
    height: 3em;
    margin: auto;
    top: 0em;

    font-family: 'RamblaBold';
    font-style: normal;
    font-weight: 700;
    font-size: 4em;
    line-height: 1.23em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    background: linear-gradient(180deg, #1C80CA 0%, #061F92 83.62%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    animation: fade .5s linear forwards;
}

.cardDisplay {
    margin: auto;
}

.center {
    position: relative;
    top: -3em;
}

.cardSet {
    width: 100em;
    height: 20em;
    margin: auto;
    margin-top: 2em;
    position: relative;
    display: flex;
    flex-direction: row;
    column-gap: 3em;
    justify-content: center;
}

.card {
    opacity: 0;
    position: relative;
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    height: 20em;
    width: 30em;
    background-color: white;
    box-shadow: 0em 0.2em 0.8em rgba(0, 0, 1, 0.4);
    z-index: 99;
}

.card:first-child {
    animation: fade .5s linear forwards;
    animation-delay: .5s;
}

.card:nth-child(2) {
    animation: fade .5s linear forwards;
    animation-delay: 1s;
}

.card:nth-child(3) {
    animation: fade .5s linear forwards;
    animation-delay: 1.5s;
}

.card:nth-child(4) {
    animation: fade .5s linear forwards;
    animation-delay: 2s;
}

.card:nth-child(5) {
    animation: fade .5s linear forwards;
    animation-delay: 2.5s;
}

.cardTitle { 
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 2.5em;
    right: 0;
    left: 0;
    width: 6em;
    height: 2.2em;
    text-align: center;
    font-size: 2.8em;
    font-family: 'RamblaBold';
    background: linear-gradient(180deg, #1C80CA 0%, #061F92 83.62%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.icon {
    position: absolute;
    top: 2em;
    left: 2em;
    height: 4em;
    width: 3.5em;
}

.leaf1 {
    width: 21.36em;
    height: 22.08em;
    position: absolute;
    right: -1.3em;
    top: 1.15em;
    z-index: 0;
}

.leaf2 {
    width: 21.36em;
    height: 22.08em;
    position: absolute;
    left: -1.3em;
    top: 1.15em;
    z-index: 0;
}

.leaf3 {
    width: 16.6256em;
    height: 27.52em;
    position: absolute;
    right: -1.85em;
    top: 23em;
    z-index: 0;
}

.leaf4 {
    width: 16.6256em;
    height: 27.52em;
    position: absolute;
    left: -1.85em;
    top: 23em;
    z-index: 0;
}

.animationBody {
    width: 100%;
    height: 42em;
}

.map {
    width: 63.52em;
    height: 27.2em;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 66.32em;
    height: 27.2em;
    top: 7em;
}

.floridaOutline {
    width: 63.52em;
    height: 27.2em;
    opacity: 0;
    position: absolute;
    animation: fade .25s linear forwards;
    animation-delay: .5s;
}

.point {
    width: 8.4em;
    height: 9.7em;
    opacity: 0;
    position: absolute;
    bottom: 7.8em;
    right: 1.24em;
    animation: fade .5s linear forwards;
    animation-delay: 1s;
}

.blobSet1 {
    opacity: 0;
    position: absolute;
    left: 19em;
    bottom: -1em;
    animation: 
        blobs .7s 1.5s linear forwards,
        hover 3.5s 2.2s ease-in-out infinite;
}

.blobSet2 {
    opacity: 0;
    position: absolute;
    right: 18em;
    bottom: -2em;
    animation: 
        blobs .7s 1.7s linear forwards,
        hover 3.5s 2.4s ease-in-out infinite;
}

.blob1 {
    width: 20em;
    height: 20em;
}

.blob2 {
    width: 20em;
    height: 20em;
}

.skyscraper {
    width: 15em;
    height: 13.5em;
    opacity: 0;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: 
        blobs .7s 2s linear forwards,
        hover 3s 2.7s ease-in-out infinite;
}

.house {
    opacity: 0;
    width: 15em;
    height: 15em;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    animation: 
        blobs .7s 2.2s linear forwards,
        hover 3s 2.9s ease-in-out infinite;
}

.clipSvg {
    clip-path: url(#myClip);
    opacity: 0;
    animation: fade 1s 1s linear forwards;
    transform: scale(.8);
}

.wave1 {
    position: absolute;
    top: 9.7em;
    left: 6em;
}

.wave2 {
    position: absolute;
    top: 12em;
    left: 10em;
}

.wave3 {
    position: absolute;
    left: 14em;
    bottom: 2.7em;
}

.wave4 {
    position: absolute;
    left: 18em;
    bottom: 5em;
}

.box {
    width: 15.2em;
    height: 1.7em;
    background-color: #1A74C3;
    animation: waves 2s alternate infinite ease-in-out;
}

@keyframes waves {
    to {
        margin-left: 103px;
    }
}

@keyframes fade {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes blobs {
    0% {
        opacity: 1;
        transform: scale(0); 
    }

    60% {
        opacity: 1;
        transform: scale(1.1); 
    }

    70% {
        opacity: 1;
        transform: scale(1); 
    }

    85% {
        opacity: 1;
        transform: scale(1.05); 
    }
    
    100% { 
        opacity: 1;
        transform: scale(1); 
     }
}

@keyframes hover {
    0% { transform: scale(1); }
    50% { transform: scale(1.03); }
    100% { transform: scale(1); }
}