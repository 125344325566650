@font-face {
  font-family: "Rambla";
  src: url(../../assets/Rambla-Regular.ttf);
}

@font-face {
  font-family: "RamblaBold";
  src: url(../../assets/Rambla-Bold.ttf);
}

.slider {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image {
  position: relative;
  width: 100vw;
  height: 44.24em;
  animation: fadeinout 8s linear forwards;
}

.rightArrow {
  right: 1em;
}

.leftArrow {
  left: 1em;
  transform: scaleX(-1);
}

.arrow {
  position: absolute;
  font-size: 3rem;
  color: white;
  z-index: 6;
  user-select: none;
  opacity: 0.5;
  transition: all 0.5s ease;
}

.rightArrow:hover {
  cursor: pointer;
  transform: scale(1.08);
  opacity: 1;
}

.leftArrow:hover {
  cursor: pointer;
  transform: scaleX(-1) scale(1.08);
  opacity: 1;
}

.slideActive {
  opacity: 1;
}

.bars {
  position: absolute;
  display: flex;
  width: 33em;
  bottom: 4.72em;
  right: 8.2%;
}

.progressBar {
  position: absolute;
  width: 4.48em;
  height: 0.48em;
  z-index: 6;

  border: 0.5px solid #ffffff;
  border-radius: 2em;
}

.bar4 {
  right: 0;
}

.bar3 {
  right: 5.5em;
}

.bar2 {
  right: 11em;
}

.bar1 {
  right: 16.5em;
}

.barActive {
  position: absolute;
  width: 4.48em;
  height: 0.48em;
  background-color: #ffffff;
  z-index: 7;
  bottom: 4.2em;
  border-radius: 2em;
  margin-right: 8.23%;
}

.activeTestimonial {
  z-index: 3;
  position: absolute;
  width: 46.8em;
  height: 13.4em;
  right: 12.32em;
  top: 16.8em;
  display: flex;

  align-items: center;
  text-align: right;

  color: #ffffff;
}

.quote {
  font-family: "RamblaBold";
  font-style: normal;
  font-weight: 100;
  font-size: 3.2em;

  animation: testimonial 0.5s linear forwards;
}

.quoteCredit {
  position: absolute;
  font-family: "Rambla";
  font-style: normal;
  font-weight: 300;
  font-size: 2.56em;
  opacity: 0;

  top: 6.5em;
  right: 0;
  text-align: right;

  animation: fade 0.25s linear forwards;
  animation-delay: 0.5s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0.2;
  }
  5% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes testimonial {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  30% {
    opacity: 0.1;
    transform: translateX(-70%);
  }

  70% {
    opacity: 0.6;
    transform: translateX(-30%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
