.container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  border-radius: 0.25rem !important;
  padding: 0.75rem !important;
  background-color: white !important;
}

.container2 {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  border-radius: 0.25rem !important;
  padding: 0.75rem !important;
  background-color: none !important;
}

.row {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.rowLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.75rem;
  padding-bottom: 0.75rem;
}

.infoSmallContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.infoLargeContainer {
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lastContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.buttonsContainer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.button {
  margin: 0 0 0 0.5rem !important;
  padding: 0.75rem !important;
  color: var(--white) !important;
  justify-content: center !important;
  align-items: center !important;
}

.label {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  margin-left: 2rem !important;
  padding: 0.375rem 0.5rem !important;
}

.icon {
  margin-right: 0.5rem !important;
  font-size: 1rem !important;
}

.text {
  text-transform: uppercase !important;
  font-weight: 700 !important;
}

.timeText {
  font-size: 0.875rem !important;
  font-style: italic !important;
  opacity: 0.95 !important;
}

.container:hover {
  cursor: pointer;
}

.container2:hover {
  cursor: pointer;
}
