@font-face {
  font-family: "RamblaBold";
  src: url(../../assets/Rambla-Bold.ttf);
}

@font-face {
  font-family: "Rambla";
  src: url(../../assets/Rambla-Regular.ttf);
}

/* .mainSection {
    position: absolute;
    top: 8em;
    height: 44.24em;
    width: 100%;
} */

.blobs {
  z-index: 3;
  position: absolute;
  animation: bounceIn 0.25s linear forwards;
}

.blob4 {
  position: absolute;
  width: 55em;
  height: 87em;
  left: 22em;
  top: -22em;
}

.blob3 {
  position: absolute;
  width: 35em;
  height: 44.8976em;
  left: 14em;
  top: -5em;
  animation: blob1 4s 0.25s ease-in-out infinite;
}

.blob2 {
  position: absolute;
  width: 40em;
  height: 30em;
  left: 5em;
  top: 12em;
  animation: blob2 4s 0.3s ease-in-out infinite;
}

.blob1 {
  position: absolute;
  width: 40em;
  height: 30em;
  left: 40em;
  top: 1em;
  animation: blob2 4s 0.35s ease-in-out infinite;
}

.smartestWay {
  position: absolute;
  width: 12em;
  height: 3.5em;
  left: 7.2em;
  bottom: 4.78em;
  z-index: 4;
  opacity: 0;

  font-family: "RamblaBold";
  font-style: normal;
  font-weight: 700;
  font-size: 3.84em;
  line-height: 1.23em;
  display: flex;
  align-items: center;

  color: #ffffff;
  animation: bounceIn 0.5s 0.75s linear forwards;
}

.title {
  z-index: 999999;
  position: absolute;
  width: 20em;
  height: 2em;
  left: 11em;
  top: 12em;
  opacity: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 2.4em;
  line-height: 1;
  display: flex;
  align-items: center;

  color: #ffffff;
  animation: bounceIn 0.5s 1.2s linear forwards;
}

.small {
  z-index: 999999;
  position: absolute;
  width: 20em;
  height: 2em;
  left: 15.5em;
  top: 20.5em;
  opacity: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 1.8em;
  line-height: 1;
  display: flex;
  align-items: center;

  color: #ffffff;
  animation: bounceIn 0.5s 1.2s linear forwards;
}

.cta {
  z-index: 999999;
  position: absolute;
  width: 20em;
  height: 2em;
  left: -1.5em;
  top: 6em;
  opacity: 0;

  animation: bounceIn 0.5s 1.2s linear forwards;
}

/* .text {
    z-index: 5;
    position: absolute;
    width: 20em;
    height: 4.72em;
    left: 14.4em;
    top: 13.2em;
    opacity: 0;

    font-style: normal;
    font-weight: 400;
    font-size: 1.92em;
    line-height: 1.2em;
    display: flex;
    align-items: center;

    color: #FFFFFF;
    animation: bounceIn .5s 1.2s linear forwards;
} */

.regularText {
  position: absolute;
  font-family: "Rambla";
}

.boldText {
  top: 2.36em;
  left: 1.2em;
  position: absolute;
  font-family: "RamblaBold";
}

@keyframes bounceIn {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes blob1 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-3%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes blob2 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(3%);
  }
  100% {
    transform: translateX(0);
  }
}
