.input {
  width: 100% !important;
}

.clearIconLast {
  border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-right: 1px solid rgba(34, 36, 38, 0.15) !important;
  background-color: white !important;
}

.clearRoundedIconLast {
  border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-right: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
  background-color: white !important;
}

.input div[role="listbox"] {
  min-width: 0px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  /*   background-color: var(--gray150) !important; */
  background-color: #2770dd !important;
  color: var(--white) !important;
  font-weight: bold !important;
}
