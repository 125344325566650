.title {
  font-size: 2rem !important;
  font-weight: 700 !important;
  padding: 0 0 2rem 0 !important;
}

.subTitle {
  font-size: 1.125rem !important;
  font-weight: 700 !important;
  padding: 0 0 1rem 0 !important;
}

.dateText {
  text-align: right !important;
  padding: 0 0 2rem 0 !important;
}

.paragraph {
  text-align: left !important;
  padding: 0 0 1rem 0 !important;
  line-height: 1.1 !important;
}

.lastParagraph {
  text-align: left !important;
  line-height: 1.1 !important;
}

.bullet {
  text-align: left !important;
  line-height: 1.1 !important;
}

.lastBullet {
  text-align: left !important;
  padding: 0 0 1rem 0 !important;
  line-height: 1.1 !important;
}

.link {
  font-size: 1rem !important;
  color: var(--primary) !important;
}

.highlight {
  font-size: 1rem !important;
  font-weight: 700 !important;
  font-style: italic !important;
}

.whiteBox {
  padding: 1rem !important;
  background-color: var(--white) !important;
  border-radius: 0.4rem !important;
}
