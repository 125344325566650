.screen {
  min-height: 100vh !important;
}

.body {
  min-height: calc(100vh - 12.5rem - 2px) !important;
  padding: 1rem 0 !important;
}

.titleText {
  font-size: 1.5rem !important;
  padding: 2rem 0 !important;
  font-weight: 700 !important;
  color: var(--primary) !important;
}

.totalTitleText {
  font-size: 1.5rem !important;
  padding: 0.5rem 0 !important;
  text-align: left;
  font-weight: 700 !important;
  color: var(--primary) !important;
}

.subTitleText {
  font-size: 1rem !important;
  font-weight: 700 !important;
  color: var(--primary) !important;
}

.subTotalText {
  font-size: 1rem !important;
  font-weight: 700 !important;
  color: var(--black) !important;
  margin: 0 0 0 0.5rem !important;
}

.infoBar {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  background-color: var(--black) !important;
  padding: 1rem 0.5rem !important;
}

.text {
  color: var(--black) !important;
  font-size: 1.2rem !important;
}

.fees {
  color: var(--black) !important;
  font-size: 1.2rem !important;
  font-weight: bold !important;
}

.whiteText {
  color: var(--white) !important;
  font-size: 1.125rem !important;
  text-transform: uppercase !important;
}

.checkBox {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.checkBox:hover {
  cursor: pointer !important;
}

.selectedIcon {
  color: var(--primary) !important;
  font-size: 1.5rem !important;
  margin-right: 0.5rem !important;
}

.icon {
  font-size: 1.5rem !important;
  color: #888888 !important;
  margin-right: 0.5rem !important;
}

.totalContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem 0 1rem 0;
}

.alarmContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 1rem 0;
}

.paymentContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.payment {
  display: flex;
  flex: 1;
  padding: 1rem;
  align-items: center;
  justify-content: center;
}

.totalText {
  font-size: 1.3rem !important;
  font-weight: 700 !important;
  color: var(--black) !important;
  margin: 0 0 0 0.5rem !important;
}

.button {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  padding: 0.75rem 1rem !important;
}

.row {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

/* .centeredButton {
  margin: 2rem 0 0 !important;
  padding: 0.75rem 0.75rem !important;
  background-color: var(--primary) !important;
  color: var(--white) !important;
  justify-content: center !important;
  align-items: center !important;
} */

@media (max-width: 768px) {
  .body {
    height: calc(100vh - 15.5rem - 1px) !important;
  }
}

.link {
  font-size: 14px !important;
  color: #4183c4 !important;
}

.endContainer {
  padding: 2rem 0 0 0;
}

.leftButton {
  padding: 0.5rem 0.75rem !important;
  background-color: var(--white) !important;
  color: var(--primary) !important;
  justify-content: center !important;
  align-items: center !important;
}

.buttonIcon {
  margin: 0 !important;
}
