:root {
  --primary: hsl(89, 72%, 45%);
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --gray50: hsl(0, 0%, 95%);
  --gray100: hsl(0, 0%, 90%);
  --gray150: hsl(0, 0%, 85%);
  --gray200: hsl(0, 0%, 80%);
  --gray250: hsl(0, 0%, 75%);
  --gray300: hsl(0, 0%, 70%);
  --gray350: hsl(0, 0%, 65%);
  --gray400: hsl(0, 0%, 60%);
  --gray450: hsl(0, 0%, 55%);
  --gray500: hsl(0, 0%, 50%);
  --gray550: hsl(0, 0%, 45%);
  --gray600: hsl(0, 0%, 40%);
  --gray650: hsl(0, 0%, 35%);
  --gray700: hsl(0, 0%, 30%);
  --gray750: hsl(0, 0%, 25%);
  --gray800: hsl(0, 0%, 20%);
  --gray850: hsl(0, 0%, 15%);
  --gray900: hsl(0, 0%, 10%);
  --gray950: hsl(0, 0%, 5%);
  --green50: hsl(115, 50%, 95%);
  --green100: hsl(115, 50%, 90%);
  --green150: hsl(115, 50%, 85%);
  --green200: hsl(115, 50%, 80%);
  --green250: hsl(115, 50%, 75%);
  --green300: hsl(115, 50%, 70%);
  --green350: hsl(115, 50%, 65%);
  --green400: hsl(115, 50%, 60%);
  --green450: hsl(115, 50%, 55%);
  --green500: hsl(115, 50%, 50%);
  --green550: hsl(115, 50%, 45%);
  --green600: hsl(115, 50%, 40%);
  --green650: hsl(115, 50%, 35%);
  --green700: hsl(115, 50%, 30%);
  --green750: hsl(115, 50%, 25%);
  --green800: hsl(115, 50%, 20%);
  --green850: hsl(115, 50%, 15%);
  --green900: hsl(115, 50%, 10%);
  --green950: hsl(115, 50%, 5%);
  --blue50: hsl(215, 75%, 95%);
  --blue100: hsl(215, 75%, 90%);
  --blue150: hsl(215, 75%, 85%);
  --blue200: hsl(215, 75%, 80%);
  --blue250: hsl(215, 75%, 75%);
  --blue300: hsl(215, 75%, 70%);
  --blue350: hsl(215, 75%, 65%);
  --blue400: hsl(215, 75%, 60%);
  --blue450: hsl(215, 75%, 55%);
  --blue500: hsl(215, 75%, 50%);
  --blue550: hsl(215, 75%, 45%);
  --blue600: hsl(215, 75%, 40%);
  --blue650: hsl(215, 75%, 35%);
  --blue700: hsl(215, 75%, 30%);
  --blue750: hsl(215, 75%, 25%);
  --blue800: hsl(215, 75%, 20%);
  --blue850: hsl(215, 75%, 15%);
  --blue900: hsl(215, 75%, 10%);
  --blue950: hsl(215, 75%, 5%);
  --red50: hsl(0, 85%, 95%);
  --red100: hsl(0, 85%, 90%);
  --red150: hsl(0, 85%, 85%);
  --red200: hsl(0, 85%, 80%);
  --red250: hsl(0, 85%, 75%);
  --red300: hsl(0, 85%, 70%);
  --red350: hsl(0, 85%, 65%);
  --red400: hsl(0, 85%, 60%);
  --red450: hsl(0, 85%, 55%);
  --red500: hsl(0, 85%, 50%);
  --red550: hsl(0, 85%, 45%);
  --red600: hsl(0, 85%, 40%);
  --red650: hsl(0, 85%, 35%);
  --red700: hsl(0, 85%, 30%);
  --red750: hsl(0, 85%, 25%);
  --red800: hsl(0, 85%, 20%);
  --red850: hsl(0, 85%, 15%);
  --red900: hsl(0, 85%, 10%);
  --red950: hsl(0, 85%, 5%);
  --purple50: hsl(232, 50%, 95%);
  --purple100: hsl(232, 50%, 90%);
  --purple150: hsl(232, 50%, 85%);
  --purple200: hsl(232, 50%, 80%);
  --purple250: hsl(232, 50%, 75%);
  --purple300: hsl(232, 50%, 70%);
  --purple350: hsl(232, 50%, 65%);
  --purple400: hsl(232, 50%, 60%);
  --purple450: hsl(232, 50%, 55%);
  --purple500: hsl(232, 50%, 50%);
  --purple550: hsl(232, 50%, 45%);
  --purple600: hsl(232, 50%, 40%);
  --purple650: hsl(232, 50%, 35%);
  --purple700: hsl(232, 50%, 30%);
  --purple750: hsl(232, 50%, 25%);
  --purple800: hsl(232, 50%, 20%);
  --purple850: hsl(232, 50%, 15%);
  --purple900: hsl(232, 50%, 10%);
  --purple950: hsl(232, 50%, 5%);
  --yellow50: hsl(54, 98%, 95%);
  --yellow100: hsl(54, 98%, 90%);
  --yellow150: hsl(54, 98%, 85%);
  --yellow200: hsl(54, 98%, 80%);
  --yellow250: hsl(54, 98%, 75%);
  --yellow300: hsl(54, 98%, 70%);
  --yellow350: hsl(54, 98%, 65%);
  --yellow400: hsl(54, 98%, 60%);
  --yellow450: hsl(54, 98%, 55%);
  --yellow500: hsl(54, 98%, 50%);
  --yellow550: hsl(54, 98%, 45%);
  --yellow600: hsl(54, 98%, 40%);
  --yellow650: hsl(54, 98%, 35%);
  --yellow700: hsl(54, 98%, 30%);
  --yellow750: hsl(54, 98%, 25%);
  --yellow800: hsl(54, 98%, 20%);
  --yellow850: hsl(54, 98%, 15%);
  --yellow900: hsl(54, 98%, 10%);
  --yellow950: hsl(54, 98%, 5%);
}
