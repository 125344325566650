.loginContainer {
  border: 1px solid #bbb;
  border-radius: 10px;
  padding: 50px 30px;
  background: rgba(255, 255, 255, 0.8);
}

.signUpLabel {
  margin-top: 30px !important;
  font-weight: 500 !important;
  color: white !important;
  text-align: center;
}
.signUpLabel > a {
  color: var(--primary);
}

.signUpLabel > a:hover {
  color: #5e8b16 !important;
}

.headline {
  font-weight: 700 !important;
  color: #181818 !important;
  margin: 25px 0 !important;
  text-align: center;
}

.segment {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.button {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}

.bottomContainer {
  align-items: center !important;
}

.checkboxLabel {
  margin-left: 5px !important;
}
