/*********************************************/
/*Reset.css*/

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/*************************************************/

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--gray50);
	font-size: 87.5%;
	font-weight: 400;
	overflow-y: auto;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body::-webkit-scrollbar {
	width: 0.6rem;
	background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
	border-radius: 0.8rem;
	-webkit-box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0.6);
	box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0.6);
}

body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0.3);
	border-radius: 0.8rem;
	background-color: #f5f5f5;
}

/* Replace Semantic UI Base Styles*/
.ui.modal > .actions {
	padding: 1rem !important;
}

.ui.popup {
	padding: 0 !important;
}

.ui.card > .content > .header:not(.ui),
.ui.cards > .card > .content > .header:not(.ui) {
	font-size: 1rem !important;
}

.ui.card > .content,
.ui.cards > .card > .content {
	padding: 1rem 0.75rem !important;
}

.ui.bottom.popup {
	margin: 1rem 0 0;
}

i.icon {
	margin: 0;
}

.ui.table {
	margin: 0;
}

.ui.button:disabled,
.ui.buttons .disabled.button,
.ui.disabled.active.button,
.ui.disabled.button,
.ui.disabled.button:hover {
	opacity: 0.6 !important;
}

.ui.grid > .row {
	padding-top: 0;
	padding-bottom: 0;
}

.ui.grid > .column:not(.row),
.ui.grid > .row > .column {
	padding-left: 0;
	padding-right: 0;
}

.ui.grid {
	margin: 0 !important;
}

.selected.item.addition {
	display: none !important;
}

.ui.pagination.menu .active.item {
	padding-top: 0.5rem;
	min-width: 1rem;
	background-color: rgba(0, 0, 0, 0.15);
}

.ui.pagination.menu .item {
	min-width: 1rem;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
	background-color: var(--primary) !important;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
	background-color: var(--primary) !important;
}

@media all {
	.page-break {
		display: none;
	}
}

@media print {
	html,
	body {
		height: initial !important;
		overflow: initial !important;
		padding: 0 !important;
		margin: 0 !important;
	}
}

@media print {
	.page-break {
		display: block;
		page-break-before: auto;
	}

	.exclude-from-print {
		display: none;
	}
}

@page {
	size: A4 portrait;
	margin: 0 10mm;
}
