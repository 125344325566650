.screen {
  min-height: 100vh !important;
}

.gridContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.body {
  display: flex !important;
  height: calc(100vh - 9rem - 2px) !important;
  align-items: center !important;
  justify-content: center !important;
  background-image: url("../../assets/login-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.black {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-evenly !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
}

@media (max-width: 768px) {
  .body {
    height: calc(100vh - 12rem - 1px) !important;
  }
}


.loginContainer {
  border: 1px solid #bbb;
  border-radius: 10px;
  padding: 25px 15px;
  background: rgba(255, 255, 255, 0.8);
}

.headline {
  font-weight: 700 !important;
  color: #181818 !important;
  margin: 25px 0 !important;
  text-align: center;
}

.segment {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.button {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}
