.container {
  padding: 0;
  border: 1px solid var(--gray500);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  margin-bottom: 1rem;
}

.cardTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  color: var(--primary) !important;
  margin-right: 1rem !important;
  font-size: 1.25rem !important;
}

.boldText {
  font-weight: bold !important;
}

.accordionContainer {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.accordionTitle {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: var(--gray300) !important;
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.accordionText {
  font-size: 0.75rem !important;
  color: var(--white) !important;
}

.accordionIcon {
  font-size: 0.75rem !important;
  color: var(--white) !important;
}

.lienCardAccordionContent {
  padding: 0 1rem !important;
}

