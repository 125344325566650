.sectionContainer {
  display: flex;
  justify-content: center;
  flex: 2;
  padding: 0.5rem 0;
  background-color: var(--primary);
  color: var(--white) !important;
  border-radius: 10px 10px 0 0;
}

.statusContainer {
  flex: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
}

.sectionText {
  font-weight: 600 !important;
  text-align: center;
  font-size: 16px !important;
  padding: 5px 10px !important;
  line-height: 20px !important;
  color: #000 !important;
  text-transform: uppercase !important;
}

.sectionRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1.5rem 0 0 0;
}

.firstSectionRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
}

.contentContainer {
  padding: 0.75rem;
  border: 1px solid #bdbdbd !important;
}
