* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.container {
    max-width: 1170px;
    margin: auto;
}

ul {
    list-style: none;
}


body {
    /* background: blue; */
}

.footer {
    position: absolute;
background: white;
height: auto;
width: 100vw;
font-family: Arial, Helvetica, sans-serif;
padding-top: 70px 0;
top: 280em;
z-index: 9999999;

}

.row {
    display: flex;
    flex-wrap: wrap;
}



.Footer-column {
    width: 25%;
    padding: 0 15px;

}

.Footer-column h4 {
    font-size: 14px;
    color: darkblue;
    text-transform: capitalize;
    margin-bottom: 30px;
    font-weight: 500;
    position: relative;
    font-weight: bolder;
}

.Footer-column h4::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 2px;
    background-color: brown;
    box-sizing: border-box;
    width: 50px;
    font-weight: bolder;
}

.footer ul li:not(:last-child) {
    margin-bottom: 10px;
}

.footer ul li a {
    font-size: 16px;
    color: blue;
    text-decoration: none;
    font-weight: 300;

}

.Footer-column .social-links  {
    display: inline-block;
    height: 175px;
    width: 250px;
    background-color: rgba(255,255,255,0.2);
    margin: 0 6px 6px 0;
    margin-left: 200px;
    text-align: left;
    line-height: 15px;
    border-radius: 50%;
    color: darkblue;
    
}

.LIST {

    columns: 5;


}



.Footer-column p1 {
    color: mediumblue;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

.Footer-column p2 {
    color: mediumblue;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
}

.Footer-column p3 {
    color: mediumblue;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

.Footer-column p4 {
    color: mediumblue;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

.Footer-column Linkedln.svg
{
position: absolute;
left: 68.07%;
right: 30.47%;
top: 94.78%;
bottom: 4.62%;

background: #1049AA;

}



.Footer-column Twitter.svg
{
    position: absolute;
left: 70.31%;
right: 28.07%;
top: 94.82%;
bottom: 4.62%;

background: #1049AA;
}

.Footer-column Instagram.svg 
{
    position: absolute;
left: 72.71%;
right: 25.68%;
top: 94.73%;
bottom: 4.55%;
}

.Footer-column Facebook.svg 
{
    position: absolute;
left: 75.1%;
right: 23.44%;
top: 94.75%;
bottom: 4.62%;

background: #1049AA;
}

.Footer-column TikTok.svg 
{
    position: absolute;
left: 75.1%;
right: 23.44%;
top: 94.75%;
bottom: 4.62%;
background: #1049AA;
}