.footer {
  bottom: 0;
  border-top: 1px solid;
  padding: 1rem 0;
  background-color: var(--gray50);
  z-index: 1;
}

.row {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.text {
  flex: 3 !important;
  margin: 0 !important;
  font-weight: 700 !important;
  line-height: 1 !important;
  color: var(--black) !important;
}

.hyperlinkRow {
  flex: 1 !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.hyperlink {
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: 700 !important;
  line-height: 1 !important;
  color: var(--black) !important;
  opacity: 1 !important;
}

.hyperlinkActive {
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: 700 !important;
  line-height: 1 !important;
  color: var(--primary) !important;
  opacity: 1 !important;
}

.hyperlink:hover {
  color: var(--primary) !important;
}

@media (max-width: 1200px) {
  .text {
    flex: 2.2 !important;
  }
}

@media (max-width: 991px) {
  .text {
    flex: 1.6 !important;
  }
}

@media (max-width: 768px) {
  .row {
    flex-direction: column !important;
  }

  .hyperlinkRow {
    flex-direction: column !important;
  }

  .hyperlink {
    margin: 0.5rem 0 0 !important;
  }

  .hyperlinkActive {
    margin: 0.5rem 0 0 !important;
  }
}

@media (max-width: 440px) {
  .text {
    font-size: 0.9rem !important;
  }
}

@media (max-width: 395px) {
  .text {
    font-size: 0.8rem !important;
  }
}

@media (max-width: 355px) {
  .text {
    font-size: 0.7rem !important;
  }
}
