.container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  border-radius: 0.25rem !important;
  padding: 0.75rem !important;
  background-color: white !important;
}

.container2 {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  border-radius: 0.25rem !important;
  padding: 0.75rem !important;
  background-color: none !important;
}

.infoContainer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.buttonsContainer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.leftButton {
  margin: 0 0.5rem 0 0 !important;
  padding: 0.75rem 1rem !important;
  background-color: var(--primary) !important;
  color: var(--white) !important;
  justify-content: center !important;
  align-items: center !important;
}

.rightButton {
  margin: 0 0 0 0.5rem !important;
  padding: 0.75rem 1rem !important;
  background-color: var(--primary) !important;
  color: var(--white) !important;
  justify-content: center !important;
  align-items: center !important;
}

.icon {
  margin: 0 !important;
}
