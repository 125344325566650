/* .screen {
  min-height: 100vh !important;
}

.body {
  height: calc(100vh - 12.5rem - 2px) !important;
  display: flex !important;
  flex-direction: column !important;
  overflow-y: auto !important;
}



.blackLast {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-evenly !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
  padding-bottom: 2rem !important;
}

.content {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  height: 100% !important;
}

.title {
  font-size: 2.5rem !important;
  font-weight: 700 !important;
  padding: 2rem 0 !important;
  text-align: center !important;
  color: var(--white) !important;
}

.paragraph {
  font-size: 1.5rem !important;
  text-align: left !important;
  padding: 0 0 1rem 0 !important;
  line-height: 1.1 !important;
  color: var(--white) !important;
}

.info {
  flex: 3 !important;
}

.infoTable {
  flex: 19 !important;
}



.section2 {
  min-height: calc(100vh - 12.5rem - 1px) !important;
  background-image: url("../../assets/login-background.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.cardTitle {
  font-size: 1.1rem !important;
  font-weight: 700 !important;
}

/* @media (max-width: 768px) {
  .body {
    height: auto !important;
    overflow-y: hidden !important;
  }

  .section1 {
    height: calc(100vh - 9.5rem - 1px) !important;
  }

  .section2 {
    height: calc(100vh - 9.5rem - 1px) !important;
  }
} */

.black {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-evenly !important;
  height: calc(100vh - 15rem - 1px) !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.content {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  height: 100% !important;
}

.section1 {
  min-height: calc(100vh - 15rem - 1px) !important;
  background-image: url("../../assets/background.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  text-align: center;
  background-attachment: fixed;
}

@media (max-width: 550px) {
  .title {
    font-size: 1.75rem !important;
  }

  .paragraph {
    font-size: 1.1rem !important;
  }
}

.gridRow {
  padding: 0.5rem 0 !important;
}

.gridColumn {
  padding: 0 0.5rem !important;
}

.col {
  padding: 5em 6em !important;
  transition: background-color 0.6s ease !important;
}

.col:hover {
  background-color: #ebf6dc !important;
}
