.item {
	display: flex;
	flex-direction: row;
	padding: 0.25rem 0;
}

.iconContainer {
	display: flex;
	height: 2.5rem;
	width: 2.5rem;
	border-radius: 1.25rem;
	margin-right: 0.5rem;
	justify-content: center;
	align-items: center;
}

.icon {
	margin: 0 !important;
}

.messageColor {
	background-color: var(--green200);
}

.negotiationColor {
	background-color: var(--blue200);
}

.counterColor {
	background-color: var(--yellow350);
}

.systemColor {
	background-color: var(--purple200);
}

.messageIconColor {
	color: var(--green700);
}

.negotiationIconColor {
	color: var(--blue700);
}

.counterIconColor {
	color: var(--yellow700);
}

.systemIconColor {
	color: var(--purple700);
}

.contentContainer {
	display: flex;
	flex-direction: column;
	max-width: calc(100% - 3.5rem);
}

.header {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.systemHeader {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 0.375rem;
}

.headerText {
	font-size: 1.125rem !important;
	font-weight: 700 !important;
}

.timeText {
	font-size: 0.875rem !important;
	font-style: italic !important;
	opacity: 0.75 !important;
}

.headerContainer {
	max-width: calc(100% - 8.25rem);
}

.timestampContainer {
	margin-left: 0.5rem;
}

.content {
	padding: 0.5rem 0;
}

.disclaimer {
	font-size: 0.875rem !important;
	font-style: italic !important;
	margin-top: 1rem !important;
	opacity: 0.75 !important;
}

.buttonRow {
	margin-top: 1rem;
}

.button {
	padding: 0.5rem 0.75rem !important;
	margin: 0 0.5rem !important;
}

.button:hover {
	background-color: var(--blue700) !important;
}

.buttonIcon {
	font-size: 0.875em !important;
}

.counterPopup {
	margin: 0.25rem 0 0 !important;
	padding: 1.5rem !important;
}

.counterText {
	font-weight: 700 !important;
	text-transform: capitalize !important;
	font-size: 1rem !important;
	text-align: center !important;
	margin: 0 0 1rem !important;
}

.counterAccordion {
	font-weight: 700 !important;
	text-transform: capitalize !important;
	font-size: 1rem !important;
	text-align: center !important;
	background-color: var(--gray200) !important;
	border-radius: 0.25rem !important;
}

.counterTextSimple {
	font-size: 1rem !important;
	margin: 0 0 0.5rem !important;
}

.counterInput {
	margin: 0 0 1rem !important;
}

.counterModal {
	width: 350px !important;
	padding: 1rem !important;
}

.counterIcon {
	margin: 0 0 0 1rem !important;
	font-size: 0.9rem !important;
}

.counterButton {
	padding: 0.5rem 1rem !important;
	background-color: var(--purple500) !important;
	color: var(--white) !important;
}

.downloadButton {
	padding: 0.5rem 0.75rem !important;
	margin: 0 0 1rem !important;
}

.downloadButton:hover {
	background-color: var(--green700) !important;
}
