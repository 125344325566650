.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 1.5rem;
  color: var(--primary);
  font-weight: bold;
}

.text {
  font-size: 0.75rem;
  font-weight: bold;
  white-space: normal;
}

.wrapper {
  height: calc(100vh - 12.5rem);
}
