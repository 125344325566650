.container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  border-radius: 0.25rem !important;
  padding: 0.75rem !important;
  background-color: white !important;
}

.container2 {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  border-radius: 0.25rem !important;
  padding: 0.75rem !important;
  background-color: none !important;
}

.row {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.infoContainer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.buttonsContainer {
  margin: 0 0 0 0.5rem !important;

  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.button {
  padding: 0.75rem !important;
  color: var(--white) !important;
  justify-content: center !important;
  align-items: center !important;
}

.icon {
  margin-right: 0.5rem !important;
  font-size: 1rem !important;
}

.text {
  text-transform: uppercase !important;
  font-weight: 700 !important;
}

.timeText {
  font-size: 0.875rem !important;
  font-style: italic !important;
  opacity: 0.95 !important;
}
