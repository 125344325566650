.item {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
}

.grid {
  width: 100% !important;
}

.gridRow {
  padding: 0.25rem 0 !important;
}

.boldText {
  font-weight: bolder !important;
  font-size: 0.875rem !important;
  text-transform: capitalize !important;
  margin-bottom: 0.25rem !important;
}

.text {
  font-size: 1rem !important;
  text-transform: capitalize !important;
}
