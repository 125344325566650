.banner {
  margin-top: -6rem;
  margin-bottom: 3rem;
}

.bannerText {
  font-size: 2.5rem !important;
  font-weight: 700 !important;
  color: var(--white) !important;
  text-align: center !important;
  margin: 0 0 1rem 0 !important;
}

.text {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  color: var(--white) !important;
  text-align: center !important;
  margin: 0 0 3rem 0 !important;
}

.searchBarContainer {
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: rgba(230, 230, 230, 0.75);
  width: 100%;
}

@media (max-width: 768px) {
  .bannerText {
    font-size: 2rem !important;
  }

  .text {
    font-size: 1.25rem !important;
    margin: 0 0 2rem 0 !important;
  }
}

@media (max-width: 550px) {
  .bannerText {
    font-size: 1.75rem !important;
  }

  .text {
    margin: 0 0 1rem 0 !important;
  }
}

