.container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  width: 100% !important;
  height: 100% !important;
  padding: 1rem !important;
}

.menuItemLast {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 1rem !important;
  margin: 0 0 0.5rem 0 !important;
  background-color: #5ba011 !important;
}

.menuItem {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 1rem !important;
  margin: 0 0 0.5rem 0 !important;
  background-color: #5ba011 !important;
}

.menuItem:hover {
  background-color: rgba(90, 160, 17, 0.4) !important;
}

.menuItemLast:hover {
  background-color: rgba(90, 160, 17, 0.4) !important;
}

.activeMenuItem {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 1rem !important;
  margin: 0 0 0.5rem 0 !important;
  background-color: var(--white) !important;
}

.hyperlink {
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: 700 !important;
  line-height: 1 !important;
  color: var(--white) !important;
  opacity: 1 !important;
}

.hyperlinkActive {
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: 700 !important;
  line-height: 1 !important;
  color: var(--black) !important;
  opacity: 1 !important;
}

.item {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.activeIcon {
  color: var(--black) !important;
  margin-right: 0.5rem !important;
}

.inactiveIcon {
  color: var(--white) !important;
  margin-right: 0.5rem !important;
}

.badge {
  font-size: 0.75rem !important;
  padding: 0.25rem 0.5rem !important;
  margin: 0 !important;
}

.navBar {
  display: flex !important;
  flex: 1 !important;
  background-color: var(--primary) !important;
}
