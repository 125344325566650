.screen {
  min-height: 100vh !important;
}

.body {
  display: flex !important;
  height: calc(100vh - 12.5rem - 2px) !important;
  align-items: center !important;
  justify-content: center !important;
}

.container {
  display: flex !important;
  height: 100% !important;
  padding: 0.5rem 0 !important;
}

.navBar {
  display: flex !important;
  flex: 1 !important;
  background-color: var(--primary) !important;
}

.content {
  display: flex !important;
  flex-direction: column !important;
  flex: 4 !important;
  overflow-y: auto !important;
}

@media screen and (max-width: 1200px) {
  .navBar {
    flex: 1.25 !important;
  }
}

@media screen and (max-width: 992px) {
  .navBar {
    flex: 1.5 !important;
  }
}

@media screen and (max-width: 768px) {
  .body {
    height: calc(100vh - 15.5rem - 1px) !important;
  }

  .navBar {
    flex: 1.5 !important;
  }
}
