.screen {
	min-height: 100vh;
}

.body {
	min-height: calc(100vh - 9rem - 2px) !important;
	padding: 1rem 0 !important;
}

.title {
	font-size: 2rem !important;
	font-weight: 700 !important;
	text-align: center !important;
}

.subTitle {
	font-size: 1.2rem !important;
	font-weight: 700 !important;
	padding: 0 0 1rem 0 !important;
}

.headerContainer {
	display: flex !important;
	flex-direction: row !important;
	justify-content: space-between !important;
	align-items: center !important;
	margin-bottom: 1em !important;
}

.buttonRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 1rem 0;
}

.button {
	margin: 0 !important;
	padding: 0.5rem 1rem !important;
	background-color: var(--purple500) !important;
	color: var(--white) !important;
}

.buttonIcon {
	font-size: 1.5rem !important;
	padding: 0 0.5rem !important;
}

.buttonIcon:hover {
	cursor: pointer;
	opacity: 0.5;
}

.button:hover {
	background-color: var(--purple700) !important;
}

.buttonInline {
	margin: 0 0 0 1rem !important;
	padding: 0.5rem 1rem !important;
	background-color: var(--purple500) !important;
	color: var(--white) !important;
}

.buttonInline:hover {
	background-color: var(--purple700) !important;
}

.row {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.textRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.5rem 0;
}

.grid {
	height: 100% !important;
}

.whiteBox {
	padding: 0.5rem 1rem;
	margin: 0 0 1rem 1rem;
	display: flex;
	flex-direction: column;
	background-color: var(--white);
	border: 1px solid var(--gray500);
	border-radius: 0.5rem;
	max-height: 100%;
}

.chatBox {
	display: flex;
	flex-direction: column;
	background-color: var(--white);
	border: 1px solid var(--gray500);
	border-radius: 0.5rem;
	height: 100%;
}

.boldText {
	font-size: 1rem !important;
	font-weight: 700 !important;
}

.titleText {
	font-size: 1.125rem !important;
	font-weight: 700 !important;
	text-align: center !important;
	margin: 0 0 1rem !important;
}

.text {
	font-size: 1rem !important;
	padding-left: 0.5rem !important;
	text-transform: capitalize !important;
}

.list {
	width: 100%;
	height: 100%;
	padding: 0.5rem 1rem 0.25rem;
	overflow-y: auto;
}

.bottomBar {
	height: 3rem;
	border-top: 1px solid var(--gray300);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0.25rem 0.5rem;
}

.input {
	margin: 0 1rem !important;
}

.noTyping {
	display: flex;
	flex: 1;
	justify-content: space-evenly;
}

.addNoteContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 1rem 0;
}

.height100 {
	height: 100%;
}

.notePopup {
	margin: 0.25rem 0 0 !important;
	padding: 1.5rem !important;
}

.noteText {
	font-weight: 700 !important;
	text-transform: capitalize !important;
	font-size: 1rem !important;
	text-align: center !important;
	margin: 0 0 1rem !important;
}

.noteAccordion {
	font-weight: 700 !important;
	text-transform: capitalize !important;
	font-size: 1rem !important;
	text-align: center !important;
	background-color: var(--gray200) !important;
	border-radius: 0.25rem !important;
}

.noteTextSimple {
	font-size: 1rem !important;
	margin: 0 0 0.5rem !important;
}

.noteInput {
	margin: 0 0 1rem !important;
}

.noteModal {
	width: 350px !important;
}

.noteIcon {
	margin: 0 0 0 1rem !important;
	font-size: 0.9rem !important;
}

.noteButton {
	margin: 0 0 1rem !important;
	padding: 0.5rem 1rem !important;
	background-color: var(--purple500) !important;
	color: var(--white) !important;
}

.sticky {
	position: sticky !important;
}

.shareAccordion {
	font-weight: 700 !important;
	text-transform: capitalize !important;
	font-size: 1rem !important;
	text-align: center !important;
	background-color: var(--gray200) !important;
	border-radius: 0.25rem !important;
	padding: 0.25rem 0 !important;
	margin-bottom: 0.5rem !important;
}

.lastShareAccordion {
	font-weight: 700 !important;
	text-transform: capitalize !important;
	font-size: 1rem !important;
	text-align: center !important;
	background-color: var(--gray200) !important;
	border-radius: 0.25rem !important;
	padding: 0.25rem 0 !important;
}

.shareIcon {
	margin: 0 0 0 1rem !important;
	font-size: 0.9rem !important;
}

.fileInput {
	width: 1px;
	height: 1px;
}
