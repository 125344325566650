.document {
  padding: 1.5rem;
  background-color: var(--white);
  border-radius: 0.4rem;
  text-align: center;
}

.title {
  font-weight: 700 !important;
  text-align: center !important;
}

.boldText {
  color: var(--black) !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}

.text {
  padding-left: 0.25rem !important;
  text-transform: capitalize !important;
}

.addressText {
  padding-left: 0.25rem !important;
  text-transform: uppercase !important;
}

.textRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 0;
}
