.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1.5rem 0 0 0;
}

.firstContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleContainer {
  display: flex;
  justify-content: center;
  flex: 2;
  padding: 0.5rem 0;
  background-color: var(--gray100);
}

.statusContainer {
  flex: 4;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
}

.titleText {
  font-weight: 600 !important;
  text-align: center;
  font-size: 14px !important;
  padding: 3px 8px !important;
  line-height: 18px !important;
  color: #000 !important;
  text-transform: uppercase !important;
}

.icon {
  padding-right: 1rem !important;
}

.contentContainer {
  padding: 0.5rem;
  border: 1px solid var(--gray100);
}

.emptyText {
  font-weight: 700 !important;
  text-align: center !important;
  text-transform: capitalize !important;
}

.boldText {
  font-weight: 700 !important;
  text-transform: capitalize !important;
}

.text {
  padding-left: 0.25rem !important;
  text-transform: capitalize !important;
}

.textRowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--gray150);
}

.textRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.textRow2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 3;
}

.gridRow {
  padding: 0.5rem 0 !important;
}
