@font-face {
    font-family: 'Inter';
    src: url(../../assets/Inter-SemiBold.ttf);
}

@font-face {
    font-family: 'InterBold';
    src: url(../../assets/Inter-Bold.ttf);
}

.header {
    position: relative;
    border: 0;
    padding:10px 0 !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 1000;
    font-size: 1.28em;
    display: flex;
    align-items: center;
    color: rgb(0, 0, 0);
    background-color: white;
    text-align: center;

    transition: all .5s ease;
}


.header:hover {
    font-family: 'InterBold';
    cursor: pointer;
    transform: scale(1.2);
    color: #1049AA;
}

.home {
    left: 0em;
}

.aboutUs {
    left: 3.5em;
}

.requestDemo {
    left: 7em;
}

.contactUs {
    left: 30%;
}

.signIn {
    right: 0;
}

.body {
    position: absolute;
    width: 8em;
    padding:10px 0 !important;

    font-family: 'Rambla';
    font-style: normal;
    font-weight: 700;
    font-size: 1.6em;

    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 10px;
}

.requestDemoMain {
    display: inline;
    left: 25.3em;
    top: 21.8em;
    z-index: 4;
    padding: 1px;
    border: 0;

    color: #1049AA;
    background-color: #FFFFFF;
    transition: all .5s ease;
}

.requestDemoMain:hover {
    font-family: 'Rambla';
    cursor: pointer;
    transform: scale(1.08);
    color: #ffffff;
    background-color: #479BFF;
    box-shadow: 0px 0px 15px #479BFF;
}
