.screen {
  /* min-height: 100vh; */
}

.body {
  /* min-height: calc(100vh - 12.5rem - 1px) !important; */
  padding: 2rem 0 !important;
}

.title {
  font-size: 2rem !important;
  font-weight: 700 !important;
  padding: 0 0 2rem 0 !important;
}

.subTitle {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  padding: 0 0 1rem 0 !important;
}

.whiteBox {
  padding: 2rem !important;
  background-color: var(--white) !important;
  border-radius: 0.4rem !important;
}

.infoContainer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.liensContainer {
  padding-bottom: 1rem !important;
}

.summaryContainer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  background-color: var(--primary) !important;
  padding: 0.5rem 0 !important;
  height: 9rem !important;
}

.totalText {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  color: var(--white) !important;
}

.button {
  margin: 0 !important;
  padding: 0.75rem 1rem !important;
  background-color: var(--white) !important;
  color: var(--black) !important;
  justify-content: center !important;
  align-items: center !important;
}

.titleRow {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 0 0 1rem 0 !important;
}

.row {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  margin-bottom: 0.25rem !important;
}

.lienTitle {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  text-decoration: underline !important;
  margin-right: 0.5rem !important;
}

.lienText {
  font-size: 1rem !important;
  font-weight: 700 !important;
}

.lienSubText {
  font-size: 1rem !important;
}

.inputContainer {
  background-color: #e2e2e2;
  border: 1px solid var(--primary) !important;
  padding: 0.125rem 0.5rem !important;
  border-radius: 0.5rem !important;
}

@media (max-width: 550px) {
  .whiteBox {
    padding: 1.5rem !important;
  }
}

.headerContainer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start;
}

.ctas {
  margin-left: auto;
  order: 2;
}

.sharePopup {
  padding: 1em !important;
  text-align: center !important;
}

.sharePopup h5 {
  margin: 1em 0 !important;
}

.boldText {
  font-weight: bold !important;
}

.column {
  height: 100%; /* allows both columns to span the full height of the browser window */
  display: flex;
  flex-direction: column; /* places the left and right headers above the bottom content */
}

ul {
  display: inline-flex;
  list-style: none;
}

.bottom {
  flex-grow: 1; /* ensures that the container will take up the full height of the parent container */
  overflow-y: auto; /* adds scroll to this container */
}

.columnLayout {
  display: flex !important;
}

.wrapper {
  display: flex;
  height: 100vh;
}

.left {
  float: left !important;
  width: 75% !important;
}

.right {
  width: 20% !important;
  height: 340px !important;
  padding: 1em !important;
  border: 1px solid #ccc !important;
  float: right !important;
}
