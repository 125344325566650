.row {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.inputContainer {
  flex: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input {
  width: 100% !important;
  padding: 0.0625rem 0 !important;
}

.autocompleteInput {
  width: 100%;
}

.separator {
  display: flex !important;
  flex: 0.5 !important;
}

.button {
  display: flex !important;
  flex: 5 !important;
  margin: 0 !important;
  padding: 0.75rem 0 !important;
  background-color: var(--primary) !important;
  color: var(--white) !important;
  justify-content: center !important;
  align-items: center !important;
}

.button:hover {
  background-color: var(--gray800) !important;
}

@media (max-width: 768px) {
  .input {
    flex: 19 !important;
  }

  .button {
    flex: 6 !important;
  }
}

@media (max-width: 550px) {
  .input {
    flex: 18 !important;
  }

  .button {
    flex: 7 !important;
  }
}
