.button {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0.5rem 1rem !important;
  width: 9rem !important;
  color: var(--white) !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid var(--gray100) !important;
  margin: 0 !important;
}

.icon {
  margin: 0 0.5rem 0 0 !important;
}

.text {
  text-transform: uppercase !important;
  font-weight: bold !important;
  color: var(--white) !important;
}
