.screen {
  min-height: 100vh !important;
}

.body {
  min-height: calc(100vh - 12.5rem - 2px) !important;
  padding: 2rem 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.title {
  text-align: center !important;
  font-size: 3rem !important;
  font-weight: 700 !important;
}

@media (max-width: 768px) {
  .body {
    height: calc(100vh - 15.5rem - 2px) !important;
  }
}
